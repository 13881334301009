export function getChapters(state) {
  return state.chapters;
}

export function getChapterMeta(state) {
  return state.chapterMeta;
}

export function getVideos(state) {
  return state.videos;
}

export function getVideoMeta(state) {
  return state.videoMeta;
}

export function getVideo(state) {
  return state.video;
}

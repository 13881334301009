export function SET_ERROR(state, { errorCode, errorMessage }) {
  state.errorCode = errorCode;
  state.errorMessage = errorMessage;
}

export function SET_CHAPTERS(state, chapters) {
  state.chapters = chapters.data;
  state.chapterMeta = chapters.meta;
}

export function SET_VIDEOS(state, videos) {
  state.videos = videos.data ? videos.data : videos;
  state.videoMeta = videos.meta;
}

export function SET_VIDEO(state, video) {
  state.video = video;
}

export function SET_VIDEO_LIKE_STATUS(state, { status }) {
  state.video.if_liked = status;
}

export function SET_VIDEO_FAV_STATUS(state, { status }) {
  state.video.if_favorited = status;
}

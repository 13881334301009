export default {
  created() {
    const title = this.setPageTitle(this);

    if (title) document.title = title;
  },

  methods: {
    setPageTitle: function(vm) {
      const { title } = vm.$options;

      if (title) {
        return typeof title === "function" ? title.call(vm) : title;
      }
    }
  }
};

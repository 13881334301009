import state from "./states";
import * as getters from "./getter";
import * as mutations from "./mutation";
import * as actions from "./action";

export default {
  namespaced: true,
  getters,
  mutations,
  actions,
  state,
}

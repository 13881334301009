import { ApiService, AuthError } from "@/services/api.service";
import TokenService from "@/services/token.service";

const UserService = {
  oauthLogin: async function({ code }) {
    const requestData = {
      method: "GET",
      url: "auth/oauth",
      params: { code }
    };

    try {
      const response = await ApiService.request(requestData);

      TokenService.setToken(response.data.access_token);
      ApiService.setHeader();

      return response.data;
    } catch (error) {
      throw new AuthError(error.response.status, error.response.statusText);
    }
  },

  wxAuthenticate: async function(data) {
    const requestData = {
      method: "Post",
      url: `auth/wechat/authenticate`,
      data: {
        url: location.href.split("#")[0],
        ...data
      }
    };

    try {
      return ApiService.request(requestData);
    } catch (error) {
      // throw new AuthenticationError(error.response.status, error.response.statusText)
      console.log(error);
    }
  },

  logout: async function() {
    TokenService.removeStorageData();
  }
};

export default UserService;

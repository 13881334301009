<template>
  <div id="app" class="primary-bg">
    <router-view />
<!--    <bottom-nav />-->
  </div>
</template>

<script>
import { mapActions } from "vuex";
// import bottomNav from "@/components/common/bottomNav";
import { AuthMixin } from "@/mixins/auth";
import TokenService from "@/services/token.service";

export default {
  name: "App",
  mixins: [AuthMixin],
  data() {
    return {
      config: {
        bgColor: "#8E313C"
      }
    };
  },
  components: {
    // bottomNav
  },

  watch: {
    "$route.name": {
      handler(o) {
        this.setCurrentRoute(o);
      }
    },
    $route: {
      handler(o) {
        if (o.query.from && o.query.from === "qrcode") {
          this.login(o.params.id);
        }
      }
    }
  },
  created() {
    const source = this.$route.query.from;
    const vidId = this.$route.params.id;

    if (process.env.NODE_ENV === "production") {
      if (source && source === "qrcode" && vidId) {
        this.initConfig(vidId);
      } else {
        this.initConfig();
      }
    }

    this.setCurrentRoute(this.$router.currentRoute.name);
  },
  methods: {
    initConfig() {
      this.config = {
        bgColor: "#8E313C"
      };

      if (!this.isLoggedIn()) {
        this.login();
      }
    },

    login(vidId = false) {
      if (!this.getQueryString("code") && !TokenService.getOAuthCode()) {
        let url = process.env.VUE_APP_WECHAT_OA_OAUTH_URL + "?";
        let redirect_uri = document.location.origin;
        let queryStringObj = {
          appid: process.env.VUE_APP_WECHAT_OA_APPID,
          redirect_uri: encodeURIComponent(redirect_uri),
          response_type: "code",
          scope: "snsapi_userinfo",
          state: vidId ? vidId : "null",
          connect_redirect: 1
        };

        url += this.formQueryString(queryStringObj);

        window.location.href = url;
      }
    },

    formQueryString(data) {
      let queryArray = [];

      Object.keys(data).map((item, index) => {
        queryArray[index] = `${item}=${data[item]}`;
      });

      return queryArray.join("&") + "#wechat_redirect";
    },

    ...mapActions("common", ["setCurrentRoute"])
  }
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100%;
}

.primary-bg {
  background-image: url("./assets/images/background.png");
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>

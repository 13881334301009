import TokenService from "@/services/token.service";

export const AuthMixin = {
  data: () => ({}),

  methods: {
    isLoggedIn() {
      return !!TokenService.getToken();
    },

    async setUserInfo(user) {
      TokenService.setUserInfo(user);
    },

    getQueryString(name) {
      let reg = new RegExp("(^|\\?|&)" + name + "=([^&]*)(\\s|&|$)", "i");
      let r = window.location.search.substr(1).match(reg);

      if (r != null) {
        return unescape(r[2]);
      }

      return null;
    },

    logout() {
      return new Promise(resolve => {
        TokenService.removeStorageData();

        resolve(true);
      });
    }
  }
};

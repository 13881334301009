import Vue from "vue";
import VueI18n from "vue-i18n";
import zhCN from "@nutui/nutui/dist/locales/lang/zn-CH";
import customZh from "@/locale/zh";

Vue.use(VueI18n);
Object.assign(zhCN, customZh);

const messages = {
  zh: customZh
};

const i18n = new VueI18n({
  locale: "zh",
  messages
});

export default i18n;

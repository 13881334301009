import Vue from "vue";
import "@/plugins/nutui";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "@/plugins/i18n";
import "@/assets/scss/custom.css";
import "@/assets/scss/animate.scss";
import title from "@/mixins/title";
// import translate from "@/mixins/translate";
import touchScale from "@/directives/touchScale";
import { ApiService } from "@/services/api.service";
import TokenService from "@/services/token.service";

Vue.directive("touch-scale", touchScale);

Vue.mixin(title);

ApiService.init();

if (TokenService.getToken()) {
  ApiService.setHeader();
}

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount("#app");

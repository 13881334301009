const TOKEN_KEY = "YUESAI_FRONTEND_TOKEN";
const REFRESH_TOKEN_KEY = "YUESAI_FRONTEND_REFRESH_TOKEN";
const KANVIDEO_USER_INFO = "KANVIDEO_USER_INFO";
const OAUTH_CODE = "KANVIDEO_OAUTH2_CODE";

const TokenService = {
  /**
   * Set access token
   *
   * @param accessToken
   */
  setToken(accessToken) {
    localStorage.setItem(TOKEN_KEY, accessToken);
  },

  /**
   * Get access token
   *
   * @returns {string}
   */
  getToken() {
    return localStorage.getItem(TOKEN_KEY);
  },

  /**
   * Remove access token
   */
  removeToken() {
    localStorage.removeItem(TOKEN_KEY);
  },

  /**
   * Set refresh token
   *
   * @param refreshToken
   */
  setRefreshToken(refreshToken) {
    localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
  },

  /**
   * Get refresh token
   *
   * @returns {string}
   */
  getRefreshToken() {
    return localStorage.getItem(REFRESH_TOKEN_KEY);
  },

  /**
   * Remove refresh token
   */
  removeRefreshToken() {
    localStorage.removeItem(REFRESH_TOKEN_KEY);
  },

  /**
   * Set current oauth code
   *
   * @param code
   */
  setOAuthCode(code) {
    localStorage.setItem(OAUTH_CODE, code);
  },

  /**
   * Get current oauth code
   *
   * @returns {string}
   */
  getOAuthCode() {
    return localStorage.getItem(OAUTH_CODE);
  },

  /**
   * Set current user info
   *
   * @param user
   */
  setUserInfo(user) {
    localStorage.setItem(KANVIDEO_USER_INFO, JSON.stringify(user));
  },

  /**
   * Remove all storage data
   */
  removeStorageData() {
    localStorage.removeItem(KANVIDEO_USER_INFO);
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(OAUTH_CODE);
  }

};

export default TokenService;

import UserService from "@/services/user.service";
import { AuthError } from "@/services/api.service";

export async function setCurrentRoute({ commit }, route) {
  commit("SET_CURRENT_ROUTE", route);
}

export async function oauthLogin({ commit }, params) {
  try {
    const userInfo = await UserService.oauthLogin(params);

    commit("OAUTH_LOGIN", userInfo.data);

    return userInfo.data;
  } catch (e) {
    if (e instanceof AuthError) {
      commit("LOGIN_ERROR", {
        errorCode: e.errorCode,
        errorMessage: e.message
      });
    }
  }
}

export function logout() {
  UserService.logout().then(() => {
    location.reload();
  });
}

import VideoService from "@/services/video.service";
import { AuthError } from "@/services/api.service";

export async function setChapters({ commit }, { page }) {
  try {
    const result = await VideoService.getChapters({ page });

    commit("SET_CHAPTERS", result);
    return result.data;
  } catch (error) {
    if (error instanceof AuthError) {
      commit("SET_ERROR", {
        errorCode: error.errorCode,
        errorMessage: error.message
      });
    }
  }
}

export async function setVideos({ commit }, params) {
  try {
    const result = await VideoService.getVideos(params);
    commit("SET_VIDEOS", result);
    return result.data;
  } catch (error) {
    if (error instanceof AuthError) {
      commit("SET_ERROR", {
        errorCode: error.errorCode,
        errorMessage: error.message
      });
    }
  }
}

export async function setVideo({ commit }, id) {
  try {
    const result = await VideoService.getVideo(id);

    commit("SET_VIDEO", result.data);
    return result.data;
  } catch (error) {
    if (error instanceof AuthError) {
      commit("SET_ERROR", {
        errorCode: error.errorCode,
        errorMessage: error.message
      });
    }
  }
}

export async function setVideoLikeStatus({ commit }, data) {
  commit("SET_VIDEO_LIKE_STATUS", data);
}

export async function setVideoFavStatus({ commit }, data) {
  commit("SET_VIDEO_FAV_STATUS", data);
}

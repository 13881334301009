import Vue from "vue";
import VueRouter from "vue-router";
import UserService from "@/services/user.service";
import wx from "weixin-js-sdk";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    redirect: { name: "List" },
    component: () => import("@/views/Home")
  },
  {
    path: "/list",
    name: "List",
    component: () => import("@/views/List")
  },
  {
    path: "/video/:id",
    name: "Video",
    component: () => import("@/views/Video")
  },
  {
    path: "/me",
    name: "Me",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "@/views/Me.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.afterEach(() => {
  const jsApiList = ["updateAppMessageShareData", "updateTimelineShareData"];

  if (process.env.NODE_ENV === "production") {
    UserService.wxAuthenticate({ apis: jsApiList }).then(res => {
      wx.config(res.data);
    });
  }
});

export default router;

export default {
  errorCode: null,
  errorMessage: "",
  chapters: [],
  videos: [],
  video: null,
  videoLikeStatus: null,
  videoFavStatus: null,
  videoMeta: null,
  chapterMeta: null
};

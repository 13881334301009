export function SET_CURRENT_ROUTE(state, route) {
  state.currentRoute = route;
}

export function OAUTH_LOGIN(state, userInfo) {
  state.wechatUserInfo = userInfo;
}

export function LOGIN_ERROR(state, { errorCode, errorMessage }) {
  state.authenticating = false;
  state.authenticationErrorCode = errorCode;
  state.authenticationError = errorMessage;
}

import { ApiService, AuthError } from "@/services/api.service";

const VideoService = {
  getChapters: async function({ page }) {
    const requestData = {
      method: "GET",
      url: "/chapter/list",
      params: { page }
    };

    try {
      const response = await ApiService.request(requestData);

      return response.data;
    } catch (error) {
      throw new AuthError(error.response.status, error.response.statusText);
    }
  },

  getVideos: async function(params) {
    const requestData = {
      method: "GET",
      url: "/video/list",
      params
    };

    try {
      const response = await ApiService.request(requestData);

      return response.data;
    } catch (error) {
      throw new AuthError(error.response.status, error.response.statusText);
    }
  },

  getVideo: async function(id) {
    const requestData = {
      method: "GET",
      url: `/video/video/${id}`
    };

    try {
      const response = await ApiService.request(requestData);
      console.log(response);
      return response.data;
    } catch (error) {
      throw new AuthError(error.response.status, error.response.statusText);
    }
  },

  getFavorites: async function() {
    const requestData = {
      method: "GET",
      url: `/user/favorites`
    };

    try {
      const response = await ApiService.request(requestData);

      return response.data;
    } catch (error) {
      throw new AuthError(error.response.status, error.response.statusText);
    }
  },

  thumbUp: async function(id) {
    const requestData = {
      method: "GET",
      url: `/video/thumbUp/${id}`
    };

    try {
      const response = await ApiService.request(requestData);

      return response.data;
    } catch (error) {
      throw new AuthError(error.response.status, error.response.statusText);
    }
  },

  toggleFavorite: async function(id) {
    const requestData = {
      method: "GET",
      url: `/video/addFavorite/${id}`
    };

    try {
      const response = await ApiService.request(requestData);

      return response.data;
    } catch (error) {
      throw new AuthError(error.response.status, error.response.statusText);
    }
  },

  removeFromFavorites: async function(ids) {
    const requestData = {
      method: "POST",
      url: "/user/remove-favorites",
      data: { ids }
    };

    try {
      const response = await ApiService.request(requestData);

      return response;
    } catch (error) {
      throw new AuthError(error.response.status, error.response.statusText);
    }
  }
};

export default VideoService;

import axios from "axios";
import TokenService from "./token.service";
import store from "../store";
// import router from "../router/index";

class AuthError extends Error {
  constructor(errorCode, message) {
    super(message);
    this.name = this.constructor.name;
    this.message = message;
    this.errorCode = errorCode;
  }
}

const ApiService = {
  _401Interceptor: null,

  httpHandle: null,

  /**
   * Set Base Url
   *
   * @param baseUrl
   */
  init(baseUrl) {
    this.httpHandle = axios.create({
      baseURL: baseUrl ? baseUrl : process.env.VUE_APP_BASE_URL,
      timeout: 100000,
      params: {}
    });
  },

  /**
   * Set Authorization header
   */
  setHeader() {
    this.httpHandle.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${TokenService.getToken()}`;
  },

  /**
   * Remove header
   */
  removeHeader() {
    this.httpHandle.defaults.headers.common = {};
  },

  setMultiPart() {
    this.httpHandle.defaults.headers.post["Content-Type"] =
      "multipart/form-data";

    return this;
  },

  /**
   * Get API
   *
   * @param resource
   * @returns {Promise<AxiosResponse<any>>}
   */
  get(resource) {
    return this.httpHandle.get(resource);
  },

  /**
   * Post API
   *
   * @param resource
   * @param data
   * @returns {Promise<AxiosResponse<any>>}
   */
  post(resource, data) {
    return this.httpHandle.post(resource, data);
  },

  /**
   * Put API
   *
   * @param resource
   * @param data
   * @returns {Promise<AxiosResponse<any>>}
   */
  put(resource, data) {
    return this.httpHandle.put(resource, data);
  },

  /**
   * Delete API
   *
   * @param resource
   * @returns {Promise<AxiosResponse<any>>}
   */
  delete(resource) {
    return this.httpHandle.delete(resource);
  },

  /**
   *
   * @param resource
   * @param formData
   * @returns {*}
   */
  upload(resource, formData) {
    return this.httpHandle.post(resource, formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
  },

  /**
   * Custom request
   *
   * @param data
   * @returns {AxiosPromise}
   */
  request(data) {
    return this.httpHandle(data).catch(e => {
      if (e.response.status === 401 && process.env.NODE_ENV === "production") {
        store.dispatch("common/logout");
      }
      throw new AuthError(e.response.status, e.response.data.message);
    });
  },

  /**
   * Mount the axios interceptor
   */
  mount401Interceptor() {
    this._401Interceptor = this.httpHandle.interceptors.response.use(
      response => {
        return response;
      },
      async error => {
        if (error.request.status === 401) {
          if (error.config.url.includes("auth/token")) {
            await store.dispatch("common/logout");
            throw error;
          } else {
            await store.dispatch("auth/refreshToken");

            return this.request({
              method: error.config.method,
              url: error.config.url,
              data: error.config.data
            });
          }
        }

        throw error;
      }
    );
  },

  /**
   * Unmount the axios interceptor
   */
  unmount401Interceptor() {
    this.httpHandle.interceptors.response.eject(this._401Interceptor);
  }
};

export { ApiService, AuthError };

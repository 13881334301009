const trans = {
  video: {
    pageTitle: "视频"
  },
  list: {
    pageTitle: "所有视频"
  },
  me: {
    pageTitle: "我的收藏"
  }
};

export default trans;
